import {React , useState} from "react";
import {auth, provider} from '../../../firebase-config'
import {signInWithPopup} from 'firebase/auth'
import {useNavigate } from "react-router-dom";
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";
import GoogleButton from 'react-google-button';
import CryptoJS from "crypto-js";

function Login( {setIsAuth}) {

    let navigate = useNavigate();
    const [userID, setUser] = useState();
    const secret = "parolaputernica";

    const signInWithGoogle = () => {

        signInWithPopup(auth, provider).then((result) => {

            let encryptedAuth = CryptoJS.AES.encrypt("true",secret);
            sessionStorage.setItem("mk1", encryptedAuth);
            setUser (result.user.email) ;
            let encrypted = CryptoJS.AES.encrypt(result.user.email,secret);
            sessionStorage.setItem("mk2", encrypted);
            setIsAuth(true);
            navigate("/blog");
            
        });
        
    };
    
    return (
    <Container className="loginPage justify-content-center">
        <Row className="justify-content-center">
            <Col className="mt-5 mb-4" >
                <p className="titlugoogle">SIGN IN WITH GOOGLE {userID}</p>
            </Col>
        </Row>
        <div className="justify-content-center d-flex">
            <GoogleButton onClick={signInWithGoogle} className="button_google"/>
        </div>
    </Container>
    );
}

export default Login;