import React from "react";
import "./grid.css"
import { Container, Row, Col} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { Card, CardActionArea, CardContent} from '@mui/material';
import bank from "./../../assets/bank.png";
import civil from "./../../assets/civil-right.png";
import comunities from "./../../assets/communities.png";
import customer from "./../../assets/customer.png";
import family from "./../../assets/family.png";
import enforcement from "./../../assets/law-enforcement.png";
import money from "./../../assets/save-money.png";
import suitcase from "./../../assets/suitcase.png";
import urban from "./../../assets/urbanization.png";

export default function GridLayout() {

    return (
        <div id="domenii" className="grid-div">
            <h1 className="card-container mt-5 display-4">Domenii de Activitate</h1>
            <Container className="mt-5">
                <Row className="centru">
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#bancar" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={bank} className="image-resize" alt="1"></img></Row>
                                        <Row><h2 className="card-text">Drept Bancar</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#civil" className="action">
                                <CardContent className="card-center ">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={civil} className="image-resize" alt="2"></img></Row>
                                        <Row><h2 className="card-text">Drept Civil</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card >
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#altele" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={comunities} className="image-resize" alt="3"></img></Row>
                                        <Row><h2 className="card-text">Alte Servicii</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                </Row>
                <Row className="centru">
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#muncii" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={customer} className="image-resize" alt="4"></img></Row>
                                        <Row><h2 className="card-text">Dreptul Muncii</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#familial" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={family} className="image-resize" alt="5"></img></Row>
                                        <Row><h2 className="card-text">Drept Familial</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#executare" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={enforcement} className="image-resize" alt="6"></img></Row>
                                        <Row><h2 className="card-text">Executare Silita</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                </Row>
                <Row className="centru">
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#fiscal" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={money} className="image-resize" alt="7"></img></Row>
                                        <Row><h2 className="card-text">Drept Fiscal</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#comercial" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={suitcase} className="image-resize" alt="8"></img></Row>
                                        <Row><h2 className="card-text">Drept Comercial</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                    <Col lg='3'>
                        <Card className="mb-5 card-color hvr-rectangle-out">
                            <CardActionArea href="/domenii#urbanistic" className="action">
                                <CardContent className="card-center">
                                    <Container className="card-container">
                                        <Row><img height={512} width={512} src={urban} className="image-resize" alt="9"></img></Row>
                                        <Row><h2 className="card-text">Drept Urbanistic</h2></Row>
                                    </Container>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
        

    );

};