import React from "react";
import "./Navbar.css"
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import LOGO from '../../assets/LOGO.png';

export default function LandingPage() {

  return (
        <Navbar fixed="top" expand="xl" className="min-vh-8 navbar-dark" collapseOnSelect>
          <Navbar.Brand href="/">
          <img height={188} width={703} src={LOGO} alt={LOGO} className="text LOGO_ul hvr-bounce-in Title_Colour"/>
          </Navbar.Brand>
          <Navbar.Toggle>
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="my-2 my-lg-0 ms-auto subliniat">
              <NavDropdown className="text nav-links animate_animated animate_bounceIn hover-underline-animation" align="end" title="DOMENII" id="basic-nav-dropdown" flip>
                <NavDropdown.Item href="#urbanistic">Urbanistic</NavDropdown.Item>
                <NavDropdown.Item href="#civil">Civil</NavDropdown.Item>
                <NavDropdown.Item href="#familial">Familial</NavDropdown.Item>
                <NavDropdown.Item href="#fiscal">Fiscal</NavDropdown.Item>
                <NavDropdown.Item href="#muncii">Muncii si Asigurariilor Sociale</NavDropdown.Item>
                <NavDropdown.Item href="#bancar">Bancar</NavDropdown.Item>
                <NavDropdown.Item href="#executare">Executare Silita</NavDropdown.Item>
                <NavDropdown.Item href="#comercial">Societatilor Comerciale</NavDropdown.Item>
                <NavDropdown.Item href="#altele">Alte Servicii</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>

        </Navbar>
  );

};