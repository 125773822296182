import React from "react";
import "./Header.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

export default function LandingPage() {

  return (
    <div className="header" id="acasa">
      <div className="middle">
        <h1 className="mx-5 animate__animated animate__fadeInDown" >Servicii
          De încredere
          <br></br>
          Cele mai bune servicii de avocatura din zona ta.</h1>
      </div>
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#ffffff" fill-opacity="1" d="M0,192L80,202.7C160,213,320,235,480,245.3C640,256,800,256,960,240C1120,224,1280,192,1360,176L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
    </div>

  );

};