import React from "react";
import './about.css'
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import ME from '../../assets/avocat.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import VisibilitySensor from 'react-visibility-sensor';



const About = () => {
    const [titleRef] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
      });
      const [setFocus] = React.useState(false);
    return (

        <Container id='about' className="about-div">
            <Row className="mb-5">
                <Col lg='8'>
            <h1 className="display-4 mb-2">Despre</h1>
                </Col>
            </Row>
            <Row>
                <Col className="about_container">
                    {/* imagine */}
                    <div className="about_me_tot"> 
                    <img height={1728} width={784} className="about_me " src={ME} alt="About"/>
                    </div>
                    {/* scris */}
                </Col>
                <Col>
                        <div className="about_cards ">
                            <article className="about_card">
                                <FaAward className="about_icon"/>
                                <h5>Experience</h5>
                                <motion.h1 ref={titleRef} animate={{ scale: [0, 1] }} transition={{ duration: 3 }}>
                                <small>
                                
                                <CountUp end={25} redraw={true} duration={5}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={(isVisible) => {
                                        if (isVisible) { setFocus(true); }
                                    }}>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>+ Years Working</small></motion.h1>
                            </article> 

                            <article className="about_card">
                                <FiUsers className="about_icon"/>
                                <h5>Clients</h5>
                                <motion.h1 ref={titleRef} animate={{ scale: [0, 1] }} transition={{ duration: 3 }}>
                                <small>
                                
                                <CountUp end={500} redraw={true} duration={5}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={(isVisible) => {
                                        if (isVisible) { setFocus(true); }
                                    }}>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>+ Clients</small></motion.h1>
                            </article> 

                            <article className="about_card">
                            <FiUsers className="about_icon"/>
                                <h5>Success Rate</h5>
                                <motion.h1 ref={titleRef} animate={{ scale: [0, 1] }} transition={{ duration: 3 }}>
                                <small>
                                
                                <CountUp end={98} redraw={true} duration={5}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={(isVisible) => {
                                        if (isVisible) { setFocus(true); }
                                    }}>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>%+ Percent</small></motion.h1>
                            </article>  
                        </div> 

                        <p>Misiunea noastra consta in promovarea si apararea, prin toate mijloacele specifice profesiei,
                         a drepturilor, libertatilor si a intereselor legitime ale clientilor nostri, in vederea aflarii
                         adevaruli, a infaptuirii justitiei si a respectarii garantiilor unui proces echitabil.
                         <br></br>
                            In esenta, scopul nostru este sa va ajutam!        
                        </p>
                    
                    <a href="#contact" className="btn btn-primary ">Hai sa vorbim!</a>
                </Col>    
                
                
            </Row>
            
        </Container>
    )
}

export default About