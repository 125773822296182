import React from "react";
import "./Footer.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { Container, Row } from "react-bootstrap";


export default function LandingPage() {

  return (
   
    <Container fluid className="div-prop">
      <Row className="hidden h-10">
        <p>ascuns</p>
      </Row>
      <Row className="d-flex align-items-center justify-content-center">
        <footer>
                <p className="footer-text">Designed by <span className="AnimatedGradientText spantext">RAProduction</span>. Copyright© 2022 All rights reserved</p>
        </footer>
      </Row>
      <Row className="hidden">
        <p>ascuns</p>
      </Row>
    </Container>
  );

};