import './App.css';
import React, { useState } from 'react';
import NavbarMain from './components/navbar/NavbarMain';
import NavbarSide from './components/navbar/NavbarSide';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Header from './components/header/Header';
import Grid from './components/grid/grid';
import Locate from './components/location/Location';
import Domains from './components/domains/Domains';
import Credits from './components/footer/Footer';
import { Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/blog/Pages/Home";
import CreatePost from "./components/blog/Pages/CreatePost";
import Login from "./components/blog/Pages/Login";
import "./components/navbar/Navbar.css"
import { Nav, Navbar } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import LOGO from './assets/LOGO.png';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import {useLayoutEffect} from 'react';
import CryptoJS from 'crypto-js';
import CookieConsent from 'react-cookie-consent';



const App = props => {
  const [isAuth, setIsAuth] = useState(false);
  const [userCheck, setUserCheck] = useState(0);
  const secret = "parolaputernica";
  
  useLayoutEffect(() => {
    let encrypted = window.sessionStorage.getItem("mk2");
    if (encrypted != null) {
    let bytes = CryptoJS.AES.decrypt(encrypted,secret);
    let data = bytes.toString(CryptoJS.enc.Utf8);
    setUserCheck(data);
    }
    let encryptedAuth = window.sessionStorage.getItem("mk1");
    if (encryptedAuth != null) {
      let bytesAuth = CryptoJS.AES.decrypt(encryptedAuth,secret);
      let dataAuth = bytesAuth.toString(CryptoJS.enc.Utf8);
      if(dataAuth === "true"){
        setIsAuth(true);
      }
    }
    console.log(isAuth);
});

  const signUserOut = () => {

    signOut(auth).then(() => {
      sessionStorage.clear()
      setIsAuth(false);
      let encryptedAuth = CryptoJS.AES.encrypt("false",secret);
            sessionStorage.setItem("mk1", encryptedAuth);
      window.location.pathname = "/blog"
    })
  }
  

  return (
    <div>
      
      
      <Routes><Route exact path="/" element={<div><NavbarMain/><Header/><Grid/><About/><Contact/><Locate/><Credits></Credits></div>} />
      <Route exact path="/domenii" element={<div><NavbarSide/><Domains/><Credits></Credits></div>} />

      <Route exact path="/blog" element={<div>
        
        <Navbar fixed="top" expand="xl" className="min-vh-8 navbar-dark" collapseOnSelect>
          <Navbar.Brand href="/">
            <img src={LOGO} alt={LOGO} className="text LOGO_ul hvr-bounce-in Title_Colour"/>
          </Navbar.Brand>
          <Navbar.Toggle>
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="my-2 my-lg-0 ms-auto subliniat">
              {!isAuth ?
                <Nav.Link href="/blog/login"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">LOGIN</span></Nav.Link> :
                <Nav>
                <Nav.Link href="/blog" onClick={signUserOut}><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">LOGOUT</span></Nav.Link> 
                {
                  (userCheck === "petrerobert0402@gmail.com" || userCheck === "dan.alin.contact@gmail.com" || userCheck === "av.nistordaniela@gmail.com")&&
                <Nav.Link href="/blog/CreatePost"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">CREATE POST</span></Nav.Link>
                
                }
                </Nav>
              }
            </Nav>
          </Navbar.Collapse>

        </Navbar>

        <Home isAuth={isAuth}/></div>} />
      <Route path="/blog/createpost" element={<CreatePost isAuth={isAuth}/>} />
      <Route path="/blog/login" element={<Login setIsAuth={setIsAuth}/>} />
      </Routes>
      <CookieConsent debug={true} location="bottom" style={{background: '#000', textAlign:"left"}} expires={365}>
        This site use cookies. See our privacy policy for more.
      </CookieConsent>
    </div>
  );
}


export default App;

