import React from "react";
import "./Domains.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

export default function ShowDomains() {

    return (
        <div>
            <div className="header-div"><h2 id="urbanistic">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Drept urbanistic</h1>
                <br></br>
                <p className="margin-paragraph">
                    In ultima vreme există o creștere a numărului cazurilor pe rolul instanțelor și un interes constant al cabinetului în tratarea și rezolvarea cazurilor în aceasta materie a urbanismului si legislateiei autorizarii si executării lucrarilor in construcții.

                    Urbanismul are ca principal scop, potrivit dispozițiilor Legii nr. 350/2001, lege-cadru în materia urbanismului, dirijarea evoluției complexe a localităților, prin elaborarea și implementarea strategiilor de dezvoltare spațială, durabilă și integrată, pe termen scurt, mediu și lung. Iar Legea 50/1991 stabilește normele si procedura pentru pentru obținerea autorizației de construire sau de desfiinţare a constructiilor.

                    Cabinetul vă oferă consultanță și asistență juridică cu privire la legalitatea :
                </p>
                <ul className="margin-paragraph">
                    <li>actelor administrative cu caracter normativ in materia urbanismului (hotarari de consiliu, planuri urbanistice generale, planuri urbanistice zonale și planuri ubanistice de detaliu)</li>
                    <li>a actelor administrative premergatoare emiterii autorizației de construire</li>
                    <li>certificatelor de urbanism</li>
                    <li>a autorizațiilor de construire</li>
                    <li>și a desființării construcțiilor</li>
                    <li>asigurăm asistență juridică și reprezentare in litigii având ca obiect anulare hotărâri de consiliu, anulare autorizații de construire sau certificate de urbanism</li>
                </ul>
                <br></br>
            </div>
            <div className="header-div"><h2 id="civil">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Drept civil</h1>
                <br></br>
                <p className="margin-paragraph">
                    Dreptul civil este ansamblul normelor juridice care reglementează raporturile patrimoniale si nepatrimoniale stabilite între persoane fizice şi persoane juridice aflate pe poziţii de egalitate juridică. În sistemul dreptului românesc, dreptul civil are un rol deosebit de important, întrucât, prin normele sale, contribuie la ocrotirea valorilor (drepturilor subiective) patrimoniale și nepatrimoniale ale persoanelor fizice și juridice. De asemenea, dreptul civil reprezintă o garanţie a formării unei conștiinţe juridice corecte, precum și a respectării și întăririi moralei.

                    Cabinetul nostru de avocat va oferă servicii în domeniul civil,  constând atât în consultanță cu ocazia încheierii unor acte cât și în situația neplăcuta în care  fie doriți promovarea unui litigiu fie sunteți chemați in judecată de către altă parte,  respectiv in materia:

                    Contractelor: de vânzare, de împrumut, de mandat, de depozit, antrepriză construcții , asociere, etc.

                    Drept de proprietate: vânzări imobiliare, vecinătăți, grănițuire, coproprietate, partaje, uzufruct, nuda proprietate, indiviziune, servituți, uzucapiune etc;

                    Litigii între proprietari și chiriași: evacuare, (ne)plata chiriei și a

                    întreținerii, executarea obligațiilor născute din închiriere.

                    Succesiuni reprezentare si asistență juridică proceduri notariale sau judiciare, legate de dezbaterea succesiunii, calitatea de mostenitor și iesire din indiviziune.

                    Dreptul la respectul vieții private.

                    Drepturile consumatorilor
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="familial">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Drept familial</h1>
                <br></br>
                <p className="margin-paragraph">
                    Dreptul Familiei este reprezentat de totalitatea normelor juridice care reglementează raporturile personale și patrimoniale ce izvorăsc din căsătorie, adopție și raporturile asimilate de lege, sub anumite aspecte, cu raporturile de familii, în scopul ocrotirii și întăririi familiei. Altfel spus, Dreptul Familiei reprezintă ansamblul normelor juridice aplicabile relațiilor dintre persoanele unite prin filiație (de exemplu, un copil și mama sau tatăl său) sau prin căsătorie.

                    Prin urmare, dreptul familiei abordează de exemplu căsătoria, divorțul, adopția copiilor, diverse chestiuni referitoare la răspunderea părintească (încredințarea copiilor, dreptul de vizită, etc.).
                    <br></br>
                    Cabinetul va ofera o gama largă de servicii în această ramură a dreptului, precum:
                </p>
                <ul className="margin-paragraph">
                    <li>Redactarea contractelor de căsătorie în acord cu legislația română .</li>
                    <li>Redactare cereri si reprezentare divorț, inclusiv divorțul cu element de extraneitate.</li>
                    <li>Răpiri internaționale de copii.</li>
                    <li>Plasament al minorilor.</li>
                    <li>Drepturile părinților în afara căsătoriei sau modificări ale măsurilor ce vizează copiii, după divorțul părinților (exercitarea autorității părintești, stabilirea domiciliului  minorului, drept de vizită și locuință, contribuția la întreținerea și educarea minorilor, etc).</li>
                    <li>Filiație (adopție, contestarea sau recunoașterea paternității)</li>
                    <li>Reprezentare în fața autorităților și instanțelor române</li>
                </ul>
                <br></br>
            </div>
            <div className="header-div"><h2 id="fiscal">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Drept fiscal</h1>
                <br></br>
                <p className="margin-paragraph">
                    Dreptul fiscal, ca ramură a dreptului public, reglementează raporturile juridice care apar în procesul colectării impozitelor şi taxelor de la persoanele fizice sau persoanele juridice care obţin venituri sau deţin bunuri impozabile ori taxabile, stabilite prin lege pe teritoriul fiscal al statului, precum şi raporturile juridice care apar în întregul proces de administrare a taxelor şi impozitelor.

                    Contenciosul fiscal definește toate litigiile care au la bază dreptul fiscal.

                    Cabinetul ofera asistenta juridica si reprezentare  in diverse litigii privind modul de calcul si stabilire al impozitelor de catre organele fiscale, promovarea cailor administrative si in instanta de judecata impotriva rapoartelor de inspectie fiscala si a deciziilor  emise de autoritățile fiscale din România,  impotriva deciziilor privind luarea masurilor asguratorii (sechestru), etc.
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="muncii">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Dreptul Muncii si Asigurariilor Sociale</h1>
                <br></br>
                <p className="margin-paragraph">
                    Legislația muncii definește drepturile și obligațiile angajaților/lucrătorilor și ale angajatorilor. Conflictele dintre angajator si angajat ajung de cele mai multe ori în fața instanței, unde ambele parți trebuie să cunoască normele legislative aplicabile.

                    Cabinetul ofera asistenta juridica si reprezentare in diverse litigii de munca dar si privitor la drepturile privind asigurarile sociale, respectiv anularea unor decizii de pensionare, oblogarea institutiilor la emiterea unei decizii de pensionare, recalcularea pensiei, obligarea diverselor institutii la emiterea unor adererinte privind grupele de munca.
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="bancar">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Dreptul Bancar</h1>
                <br></br>
                <p className="margin-paragraph">
                    Clauzele abuzive sau dobânzile încasate fără temei legal reprezintă temeiul intentării unei acțiuni în instanță pentru constatarea acestor nereguli si înapoirea banilor încasați ilegal de bănci către dumneavoastră.

                    Cabinetul ofera servicii de consultanta, asistenta juridica si reprezentare in instanta in litigiile cu bancile avand ca obiect anulare clauze abuzive dar dare in plata.
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="executare">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Executare Silita</h1>
                <br></br>
                <p className="margin-paragraph">
                    În ultimii ani numarul executărilor silite au crescut exponențial, în majoritatea cazurilor acestea fiind pornite de către recuperatori de creanțe. În general, atunci când băncile/companiile de telefonie/prestări servicii acumulează mai mulți debitori, aceștia formează un portofoliu de creanțe pe care le cedează societăților de recuperări creanțe.

                    În baza acestor contracte de cesiune, recuperatorii de creanțe pun în executare silită debitorii care nu și-au mai permis achitarea ratelor la creditele/serviciile contractate.

                    Susținând cauzele a nenumărați clienți aflate în această situații, am identificat cele mai frecvente 3 apărări folosite în fața instanței ce au dus la anularea executării silite.
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="comercial">Header</h2></div>
            <div className="domain-div">
                <br></br>
                <h1 className="center">Dreptul Societatilor Comerciale</h1>
                <br></br>
                <p className="margin-paragraph">
                Oferim asistenta juridica si reprezentare, redactare acte si reprezentare in fata Oficiilor Registrului Comerțului pentru infiintare societati comerciale, modificare acte constitutive, cesiuni părți sociale, dizolvare, fuziune radiere societati comercile

De asemenea  oferim servicii de reprezentare si redactare in actiuni excludere asociat , antrenare raspundere a membrilor organelor de conducere (asociati/administratori ai societatii ) in dauna societații sau a diferitelor persoane juridice de dept privat sau public .
                </p>
                <br></br>
            </div>
            <div className="header-div"><h2 id="altele">Header</h2></div>
            <div className="domain-div ultimul">
                <br></br>
                <h1 className="center">Alte Servicii</h1>
                <br></br>
                <p className="margin-paragraph">
                    <ul>
                        <li>Dobâdirea cetățeniei române, obtinere permis sedere temporară sau permanentă</li>
                        <li>Consultații</li>
                        <li>Reprezentare în fața autorităților</li>
                        <li>Recunoașterea și executarea deciziilor străine în România (exequatur).</li>
                        <li>Asistența în cursul soluționării alternative a litigiilor civile sau penale (prin intermediul medierii).</li>
                        <li>Asistență și redactare a tranzacțiilor.</li>
                        <li>Redactare și atestare de documente, certificare de data certă, atestare a indentității si calității părților, a conținutului înscrisului .</li>
                    </ul>
                </p>
                <br></br>
            </div>
        </div>
    );

};