import React from "react";
import "./Navbar.css"
import { Nav, Navbar } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import LOGO from '../../assets/LOGO.png';

export default function LandingPage() {

  return (
        <Navbar fixed="top" expand="xl" className="min-vh-8 navbar-dark" collapseOnSelect>
          <Navbar.Brand href="/">
          <img height={188} width={703} src={LOGO} alt={LOGO} className="text LOGO_ul hvr-bounce-in Title_Colour"/>
          </Navbar.Brand>
          <Navbar.Toggle>
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="my-2 my-lg-0 ms-auto subliniat">
              <Nav.Link href="/domenii"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">DOMENII</span></Nav.Link>
              <Nav.Link href="#about"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">DESPRE</span></Nav.Link>
              <Nav.Link href="/blog"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">BLOG</span></Nav.Link>
              <Nav.Link href="#contact"><span className="text nav-links animate__animated animate__bounceIn hover-underline-animation">CONTACT</span></Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Navbar>
  );

};