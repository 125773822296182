import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Location.css';

export default function GridLayout() {

    return (
        <Container>
            <div className="mt-5">
                <Row className="mb-5">
                    <Col lg='8'>
                        <h1 className="display-4 mb-2">
                        Locatie 
                        </h1>
                    </Col>
                </Row>
                <div className="parentdiv">
                <iframe title="locatie" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d719.7916163308784!2d28.581232629237263!3d43.81090639869481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40bb2956fbd6b405%3A0x452a642195fc0bb7!2sStrada%20%C8%98tefan%20cel%20Mare%207%2C%20Mangalia%20905500!5e0!3m2!1sro!2sro!4v1657387586344!5m2!1sro!2sro" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </Container>
    )

};