import React, {useState} from "react";
import './CreatePost.css';
import {addDoc, collection, serverTimestamp} from 'firebase/firestore';
import {db, auth, storage} from "../../../firebase-config";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from "react-bootstrap";



function CreatePost({isAuth}, {userID}) {

    const [title, setTitle] = useState("");
    const [postText, setPostText] = useState("");
    const [pdfUpload, setpdfUpload] = useState("");
    const [switchPost, setSwitchPost] = useState(false);

    const postsCollectionRef = collection(db, "Postari")

    let navigate = useNavigate();
    
    const uploadPDF = () => {
        if (pdfUpload == null) return;
        const pdfRef = ref(storage, `pdf/${pdfUpload.name}`);
        uploadBytes(pdfRef, pdfUpload).then((snapshot) => {
            alert(pdfUpload.name);
            getDownloadURL(snapshot.ref).then( url => setPostText(url));
        })
    };

    const createPostLink = async () => {
        await addDoc(postsCollectionRef, {
            title,
            postText,
            author: {name: auth.currentUser.displayName, id: auth.currentUser.uid},
            createdAt: serverTimestamp()});
        navigate("/blog");
    }

    const createPostPDF= async () => {
        await addDoc(postsCollectionRef, {
            title,
            postText,
            author: {name: auth.currentUser.displayName, id: auth.currentUser.uid},
            createdAt: serverTimestamp()});
        navigate("/blog");
    }
    
    const toggleSwitch = () => {
        
        setSwitchPost(current => !current);
      };

    return( 
    <div className="createPostPage"> 
        <a href="/blog" className="back_btn hvr-fade">Inapoi La Blog!</a>
        <button onClick={toggleSwitch} className="hvr-fade switch_btn">{!switchPost ? <p className="switch_text">Posteaza Pdf</p> : <p className="switch_text">Posteaza Link</p>}</button>
        { !switchPost ? <Container className="cpContainer">
            <Row>
                <h1> Create a Post Link</h1>
            </Row>
            <div className="inputGp">
                <label>
                    Title:
                </label>
                <input placeholder= "Title..." 
                onChange={(event) => {
                     setTitle(event.target.value)}}
                />
            </div>
            <div className="inputGp">
                <label>
                    Post:
                </label>
                <textarea placeholder="Post..."
                onChange={(event) => {
                    setPostText(event.target.value)}}
                />
            </div>
            <button onClick={createPostLink}> Submit Post</button>
        </Container>
        :
        <div className="cpContainer">
            <h1> Create a Post PDF</h1>
            <div className="inputGp">
                <label>
                    Title:
                    <h1>{userID}</h1>
                </label>
                <input placeholder= "Title..." 
                onChange={(event) => {
                     setTitle(event.target.value)}}
                />
            </div>

            <div>
                    <input type="file" onChange={(event) => {setpdfUpload(event.target.files[0])}}/>
                <button onClick={uploadPDF} className="hvr-fade"> Upload PDF!</button>
            </div>
            
            <button className="hvr-fade" onClick={createPostPDF}> Submit Post</button>
        </div>}
        
    </div>
    );
}

export default CreatePost;
