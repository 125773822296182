import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./contact.css";

export default function ContactUs() {
    return (
        <Container id="contact" className="contact-div">
            <Row className="mb-5">
                <Col lg='8'>
                    <h1 className="display-4 mb-2">
                       Contact 
                    </h1>
                </Col>
            </Row>
            <div className="border_contact">
                <Row className="sec_sp">
                    <Col lg='5' className="mb-5">
                        <address>
                            <strong> Email : av.nistordaniela@gmail.com</strong>
                            <br />
                            <strong> Email : dana200726@yahoo.com</strong>   
                            <br />
                            <br />
                            <p>
                            <strong> Telefon : 0752037906</strong>
                            </p>
                            <p>
                            <strong> Cabinet Avocat Nistor Daniela Doinita: <br />Constanta, Mangalia,</strong>
                            
                            <strong> strada Stefan cel Mare, nr 7, cladire Dr.Max, etaj 1, birou 2</strong>
                            </p>
                        </address>
                    </Col>
                    
                    <Col lg='7' className="d-flex align-items-center">
                        <form className="contact__form w-100" action="https://formsubmit.co/av.nistordaniela@gmail.com" method="POST">
                        <Row>
                            <Col lg='6' className="form-group">
                            <input 
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name"
                            type="text"
                            required
                            />
                            </Col>

                            <Col lg='6' className="form-group rounded-0">
                            <input 
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            required
                            />
                            </Col>
                        </Row>
                        <textarea className="form-control rounded-0" 
                            id="message"
                            name="message"
                            placeholder="Message"
                            rows='5'
                            required
                        ></textarea>
                        <br />
                        <Row>
                            <Col lg = '12' className="form-group">
                                <Button className="btn ac_btn" type="submit">Send</Button>
                            </Col>
                        </Row>
                        </form>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}