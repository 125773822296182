// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {getAuth, GoogleAuthProvider} from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzWG8vmSs3ZEP4dXokikv363HX7CG-8LE",
  authDomain: "cabinet-avocat-57af5.firebaseapp.com",
  projectId: "cabinet-avocat-57af5",
  storageBucket: "cabinet-avocat-57af5.appspot.com",
  messagingSenderId: "1063958174862",
  appId: "1:1063958174862:web:946828620dcfeaa778bddf",
  measurementId: "G-1SWQNZJQWC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();