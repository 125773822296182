import React, { useEffect, useState } from "react";
import {collection, deleteDoc, doc, onSnapshot, orderBy, query} from 'firebase/firestore';
import {auth, db, storage} from "../../../firebase-config";
import './Home.css';
import { listAll, ref, getDownloadURL} from 'firebase/storage';
import { Container, Row, Col } from "react-bootstrap";


function Home({isAuth}) {

    const [postList, setPostList] = useState([]);
    const postsCollectionRef = collection(db, "Postari");
    const [pdfList, setPdfList] = useState([]);

    const pdfListRef = ref(storage, "pdf/");

    const q = query(postsCollectionRef, orderBy('createdAt', 'desc'));

    useEffect(() => {
        listAll(pdfListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setPdfList((prev) => [...prev, url]);
                });
            });
        });
    }, []);
    
    useEffect( (snapshot) => {
        onSnapshot(q, (snapshot) => {
         setPostList(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
      }, []);

    const deletePost = async (id) => {
        const postDoc = doc(db, "Postari", id);
        await deleteDoc(postDoc);
    };



    return( <div className="homePage"> {postList.map((post) => {

        return (
        
        <Container className="post"> 
            <Row className="mb-3" > 
            {/* titlu */}
                <Col  lb='8'> 
                    <h1 className="title title_mine">
                        {post.title}
                    </h1>
                </Col>
                <Col>
                    {  isAuth && post.author.id === auth.currentUser.uid && ( 
                        <button  className="d-flex" onClick={() => {
                            deletePost(post.id);
                        }} >
                            &#128465;
                        </button>
                    )}
                </Col>
            </Row>
            <Row>
            <Col> 
            <small className="timestemp_css">
                {post.createdAt.toDate().toString()}
            </small>
            </Col>
            <Col className="buton_cu_link d-flex justify-content-end">

            <a href={post.postText}> <button className="button_redirect ">Vezi Aici!</button></a>
            </Col>
            </Row>
        </Container> );
    })}</div>
    );
    }
export default Home;